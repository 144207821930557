<template>
  <div class="article">
    <article id="content">
      <ArticleTags
        v-bind:createdDate="article.info && article.info.active_dt"
        v-bind:tags="(article.info && article.info.tags) || []"
        v-bind:updatedDate="article.info && article.info.last_update"
      ></ArticleTags>
      <VueShowdown
        v-if="article.format == 'text/markdown'"
        flavor="github"
        v-bind:markdown="article.text"
      />
      <div v-else>
        {{ article.text }}
      </div>
      <div class="byline-wrapper" v-if="article.info && article.info.byline">
        <div class="byline-separator">
          <span class="byline-separator-icon" v-for="n in 3" v-bind:key="n">
            <ArticleIcon
              v-bind:deface="deface"
              v-bind:tags="(article.info && article.info.tags) || []"
            />
          </span>
        </div>
        <div
          class="byline"
          v-if="article.info.byline.type == 'text/html'"
          v-html="article.info.byline.text"
        ></div>
        <div v-else class="byline">
          {{ article.info.byline.text }}
        </div>
      </div>
    </article>
    <Footer
      v-bind:skips="article.info.byline ? ['byline', 'disclosure'] : undefined"
    >
    </Footer>
    <Cookies />
  </div>
</template>

<script>
import axios from "axios";
import { VueShowdown } from "vue-showdown";
import ArticleIcon from "../components/ArticleIcon.vue";
import ArticleTags from "../components/ArticleTags.vue";
import Cookies from "../components/Cookies.vue";
import Footer from "../components/Footer.vue";

console.log("Loaded ARTICLE");

export default {
  name: "Home",
  components: {
    ArticleIcon,
    ArticleTags,
    Cookies,
    Footer,
    VueShowdown,
  },
  computed: {
    deface() {
      if (this.article.info.is_featured) {
        return "featured";
      } else {
        switch (this.article.info.content_type) {
          case "cheat":
            return "cheat";
          default:
            return undefined;
        }
      }
    },
  },
  created() {
    axios.get(`/posted/${this.$route.params.permalink}`).then((res) => {
      if (res.data) {
        this.article = {
          format: res.data.contentType.replace(/;? .*/, ""),
          text: Buffer.from(res.data.document, "base64").toString(),
          info: res.data.info,
        };
        if (this.article.info.byline) {
          const bl = this.article.info.byline;
          if (bl.startsWith("{")) {
            try {
              this.article.info.byline = JSON.parse(bl);
            } catch (e) {
              console.error("Byline failed to parse:", e);
              console.error("byline:", bl);
              this.article.info.byline = {
                type: "text/plain",
                text: bl,
              };
            }
          } else {
            this.article.info.byline = {
              type: "text/plain",
              text: bl,
            };
          }
        }
        console.log("article;", this.article);
      }
    });
  },
  data() {
    return {
      article: {
        format: "text/plain",
        text: "Loading...",
        info: {},
      },
    };
  },
};
</script>
