<template>
  <div class="home">
    <aside
      class="home-sidebar"
      id="cheat-notes"
      v-if="filteredCheatNotes.length + filteredArticles.length > 0"
    >
      <h2
        v-if="sidebar.featured && sidebar.featured.length > 0"
        class="featured"
      >
        Featured
      </h2>
      <ul v-if="sidebar.featured && sidebar.featured.length > 0">
        <li v-for="fa in sidebar.featured" v-bind:key="fa.id">
          <ArticleIcon deface="featured" v-bind:tags="fa.tags" />
          <router-link v-bind:to="`/article/${fa.permalink}`">
            {{ fa.title }}
          </router-link>
        </li>
      </ul>
      <h2 v-if="filteredCheatNotes.length > 0">Cheat Notes</h2>
      <ul v-if="filteredCheatNotes.length > 0">
        <li v-for="note in filteredCheatNotes" v-bind:key="note.id">
          <ArticleIcon v-bind:tags="note.tags" deface="cheat" />
          <router-link v-bind:to="`/article/${note.permalink}`">
            {{ note.title }}
          </router-link>
        </li>
      </ul>
      <h2 v-if="filteredArticles.length > 0">Articles</h2>
      <ul v-if="filteredArticles.length > 0">
        <li v-for="article in filteredArticles" v-bind:key="article.id">
          <ArticleIcon v-bind:tags="article.tags" />
          <router-link v-bind:to="`/article/${article.permalink}`">
            {{ article.title }}
          </router-link>
        </li>
      </ul>
    </aside>
    <section id="blog">
      <nav id="tags">
        <div id="important-tags">
          <div
            class="tag"
            v-for="tag in importantTags"
            v-bind:key="tag.id"
            v-bind:style="tagStyle(tag)"
            v-bind:data-selected="tag.selected"
            @click="toggleTag(tag)"
          >
            <div
              class="icon"
              v-bind:style="'background-color: ' + tag.color"
            ></div>
            {{ tag.title }}
          </div>
        </div>
        <div id="other-tags">
          <div
            class="tag"
            v-for="tag in otherTags"
            v-bind:key="tag.id"
            v-bind:style="tagStyle(tag)"
            v-bind:data-selected="tag.selected"
            @click="toggleTag(tag)"
          >
            {{ tag.title }}
          </div>
        </div>
      </nav>
      <article
        v-for="article in reflowedContent"
        v-bind:key="article.id"
        v-bind:class="article.content_type"
      >
        <div
          v-if="article.form_factor === 'feature'"
          v-bind:class="`${article.form_factor} ${article.content_type}`"
        >
          <div v-if="article.is_featured" class="featured-article-tag">
            Featured Article:
          </div>
          <ArticleIcon
            v-bind:deface="deface(article)"
            v-bind:tags="article.tags"
          />
          <router-link v-bind:to="`/article/${article.permalink}`">
            <h2>
              <span v-if="article.content_type === 'cheat'" class="cheat">
                <!-- eslint-disable-next-line -->
                [<span class="top">Cheat</span><span class="bottom">notes</span>]
              </span>
              {{ article.title }}
            </h2>
          </router-link>
          <ArticleTags
            v-bind:createdDate="article.active_dt"
            v-bind:dateLink="`/article/${article.permalink}`"
            v-bind:selected="selectedTagIds"
            v-bind:tags="article.tags"
            v-bind:updatedDate="article.last_update"
          />
          <div class="abstract">{{ article.info.abstract.text }}</div>
          <router-link v-bind:to="`/article/${article.permalink}`">
            <div class="read-more">[read more]</div>
          </router-link>
        </div>
        <div
          v-else-if="article.content_type === 'rocket-group'"
          class="rocket-group"
          v-bind:id="article.id"
        >
          <ArticleIcon
            deface="rocket"
            v-bind:tags="article.rockets.map((r) => r.tags).flat()"
          ></ArticleIcon>
          <span class="rockets">
            <div
              class="rocket-content"
              v-for="rocket in article.rockets"
              v-bind:key="rocket.id"
            >
              <div
                v-if="rocket.isEmpty"
                class="rocket-placeholder"
                v-bind:id="`rocket-placeholder${rocket.id}`"
              ></div>
              <RocketArticle
                v-else
                v-bind:article="rocket"
                v-bind:selectedTagIds="selectedTagIds"
              />
            </div>
          </span>
        </div>
        <RocketArticle
          v-else-if="article.content_type === 'rocket'"
          v-bind:article="article"
          v-bind:selectedTagIds="selectedTagIds"
        />
        <div v-else></div>
      </article>
    </section>
    <Footer />
    <Cookies />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
import { timeAgoUntil } from "nice-dates";
import ArticleIcon from "../components/ArticleIcon.vue";
import ArticleTags from "../components/ArticleTags.vue";
import Cookies from "../components/Cookies.vue";
import Footer from "../components/Footer.vue";
import RocketArticle from "../components/RocketArticle.vue";

export default {
  name: "Home",
  components: {
    ArticleIcon,
    ArticleTags,
    Cookies,
    Footer,
    RocketArticle,
  },
  computed: {
    articles() {
      return this.allArticles;
    },
    deface() {
      return function (article) {
        if (article.is_featured) {
          return "featured";
        } else if (["rocket", "cheat"].includes(article.content_type)) {
          return article.content_type;
        }
      };
    },
    filteredArticles() {
      let result = this.filteredContent.filter(
        (a) => a.content_type === "article"
      );
      // eslint-disable-next-line
      result.sort((a,b) => (a.title > b.title) ? 1 : ((a == b) ? 0 : -1));
      return result;
    },
    filteredCheatNotes: function () {
      let result = this.filteredContent.filter(
        (a) => a.content_type === "cheat"
      );
      // eslint-disable-next-line
      result.sort((a,b) => (a.title > b.title) ? 1 : ((a == b) ? 0 : -1));
      return result;
    },
    filteredContent: function () {
      let result = this.allArticles;
      let selectedTags = this.allTags.filter((tag) => tag.selected);
      if (selectedTags.length) {
        result = result.filter((article) => {
          let foundTag = false;
          for (let at of article.tags) {
            for (let st of selectedTags) {
              if (at.id === st.id) {
                foundTag = true;
                break;
              }
            }
            if (foundTag) {
              break;
            }
          }
          return foundTag;
        });
      }
      // clone every member
      result = result.map((r) => {
        return { ...r };
      });
      for (let article of result) {
        switch (article.content_type) {
          case "rocket":
            article.overlineParts = [];
            for (let tag of article.tags) {
              if (tag.important) {
                article.overlineParts.push({
                  style: `background-color: ${tag.color}; position: absolute; `,
                });
              }
            }
            if (article.overlineParts.length > 0) {
              let opHeight = 100.0 / article.overlineParts.length;
              article.overlineParts.forEach((op, index) => {
                op.style += `top: ${index * opHeight}%; height: ${opHeight}%`;
                op.index = index;
              });
            }
            {
              let rockets = [{ ...article }];
              for (const k of Object.keys(article)) {
                delete article[k];
              }
              article.content_type = "rocket-group";
              article.form_factor = "combined";
              article.rockets = rockets;
            }
            break;
        }
      }
      result.filterKey = this.filterKey;
      return result;
    },
    importantTags: function () {
      let tags = Object.values(this.allTags);
      tags = tags.filter((tag) => {
        return tag.important === true;
      });
      return this.tagSort(tags);
    },
    otherTags: function () {
      let tags = Object.values(this.allTags);
      tags = tags.filter((tag) => {
        return tag.important === false;
      });
      return this.tagSort(tags);
    },
    selectedTagIds: function () {
      if (this.allTags) {
        return this.allTags.filter((tag) => tag.selected).map((tag) => tag.id);
      } else {
        return [];
      }
    },
  },
  data() {
    let result = {
      allArticles: [],
      allTags: [],
      // for identifying placeholders. Decrement every time one is pulled so the
      // id is always negative
      emptyMiniId: -1,
      // update this to force an update to filteredContent
      filterKey: 0,
      // not-ready, dom-ready, throttled, reflowing, reflowed
      reflowStatus: "not-ready",
      reflowedContent: [],
      // for identifying rocket-groups. Decrement every time one is pulled.
      rocketGroupId: -1,
      sidebar: {},
      observer: {}, // assigned when #mounted
    };
    axios.get("/content/articles").then((articles) => {
      result.allArticles = articles.data;
      let tags = {};
      for (let article of this.allArticles) {
        if (article.tags) {
          for (let tag of article.tags) {
            tag.selected = false;
            tags[tag.id] = tag;
          }
        }
      }
      for (let tag of Object.values(tags)) {
        this.$set(this.allTags, tag.id, tag);
      }
      this.$nextTick(function () {
        this.reflowContent();
      });
    });
    axios.get("/content/sidebar").then((sidebar) => {
      result.sidebar = sidebar.data;
    });
    return result;
  },
  methods: {
    async hoistRockets(blogDom, articles) {
      // find first rocket-group with available space
      blogDom ||= document.getElementById("blog");
      articles ||= (() => {
        let result = [];
        for (let a of blogDom.getElementsByTagName("article")) {
          result.push(a);
        }
        return result;
      })();

      let numSpaces = 0;
      let itemsHoisted = 0;

      // group with available spaces on the page
      const availableRocketGroup = articles.find((a) => {
        if (a.className.split(" ").includes("rocket-group")) {
          let rocketItems = a.getElementsByClassName("rocket-content");
          let previousOffset = Number.MIN_SAFE_INTEGER;
          let spaces = 0;
          for (let ri of rocketItems) {
            if (ri.offsetLeft > previousOffset) {
              previousOffset = ri.offsetLeft;
              // eslint-disable-next-line
              if (ri.childNodes[0].className.split(" ")
                  .includes("rocket-placeholder")
              ) {
                spaces++;
              }
            } else {
              break;
            }
          }

          if (spaces) {
            numSpaces = spaces;
            return true;
          } else {
            return false;
          }
        }
      });

      if (availableRocketGroup) {
        // group in the reflowedContent
        let availableGroup = undefined;
        // find first content items after that rocket group with space
        hoistLoop: for (let rfc of this.reflowedContent) {
          if (rfc.content_type === "rocket-group") {
            if (availableGroup) {
              for (let rocketIdx = 0; rocketIdx < rfc.rockets.length; ) {
                let rocket = rfc.rockets[rocketIdx];
                if (!rocket.isEmpty) {
                  // hoist rockets into empty spaces
                  availableGroup.rockets.push(rfc.rockets[rocketIdx]);
                  rfc.rockets.splice(rocketIdx, 1);
                  itemsHoisted++;
                  if (numSpaces === itemsHoisted) {
                    break hoistLoop;
                  }
                } else {
                  rocketIdx++;
                }
              }
            } else {
              if (rfc.id === availableRocketGroup.childNodes[0].id) {
                availableGroup = rfc;
              }
            }
          }
        }
        if (availableGroup) {
          // remove all the placeholders from the group we just attempted to
          // fill
          for (let rIdx = 0; rIdx < availableGroup.rockets.length; ) {
            if (availableGroup.rockets[rIdx].isEmpty) {
              availableGroup.rockets.splice(rIdx, 1);
            } else {
              rIdx++;
            }
          }
        }
      }

      if (availableRocketGroup && itemsHoisted > 0) {
        const self = this;
        return await new Promise((resolve) => {
          self.$nextTick(() => {
            self.hoistRockets().then(resolve);
          });
        });
      } else {
        // sweep out placeholders and empty groups
        let newReflowContent = [];
        for (let rfc of this.reflowedContent) {
          newReflowContent.push(rfc);
          if (rfc.content_type === "rocket-group") {
            rfc.rockets = rfc.rockets.filter((r) => {
              return !r.isEmpty;
            });
          }
        }
        this.reflowedContent = newReflowContent.filter((rfc) => {
          return rfc.content_type !== "rocket-group" || rfc.rockets.length > 0;
        });
        return;
      }
    },
    isSelected(tag) {
      if (!tag) {
        return false;
      }
      if (!this.allTags) {
        return false;
      }
      let thisTag = this.allTags.find((t) => {
        return t && t.id === tag.id;
      });
      return thisTag ? thisTag.selected : false;
    },
    setupReflowObserver() {
      const vm = this;
      const blogObservableNode = document.getElementById("blog");

      vm.observer = new MutationObserver(this._reflow);

      vm.observer.observe(blogObservableNode, { childList: true });

      vm.reflowStatus = "dom-ready";

      if (vm.reflowedContent.length > 0) {
        this._reflow();
      }
    },
    _reflow(throttleComplete) {
      if (
        "dom-ready" === this.reflowStatus ||
        ("throttled" === this.reflowStatus && throttleComplete)
      ) {
        this.reflowStatus = "reflowing";

        // first modify the rocket-groups so that there are placeholders
        // to detect available spaces for hoisting

        this.reflowedContent.forEach((c) => {
          if (c.content_type === "rocket-group") {
            c.id = `rocket-group${--this.rocketGroupId}`;
            for (let emNum = 0; emNum < 3; emNum++) {
              c.rockets.push({ id: --this.emptyMiniId, isEmpty: true });
            }
          }
        });

        this.$nextTick(async () => {
          await this.hoistRockets();
          this.reflowStatus = "reflowed";
        });
      }
    },
    reflowContent() {
      this.reflowStatus = "dom-ready";
      this.reflowedContent = this.filteredContent;
    },
    resized() {
      if (["dom-ready", "reflowed"].includes(this.reflowStatus)) {
        this.reflowStatus = "throttled";
        setTimeout(() => {
          this.filterKey++;
          this.reflowedContent = this.filteredContent;
          this._reflow(true);
        }, 100);
      }
    },
    tagStyle(tag) {
      if (tag.color) {
        if (tag.selected) {
          return `background-color: ${tag.color}; color: white`;
        } else {
          return `color: ${tag.color};`;
        }
      }
    },
    tagSet(important) {
      let tags = Object.values(this.allTags);
      if (typeof important === "boolean") {
        tags = tags.filter((tag) => {
          return tag.important === important;
        });
      }
      return this.tagSort(tags);
    },
    tagSort(tags) {
      return tags.sort((a, b) => {
        if (a.important && !b.important) {
          return -1;
        } else if (b.important && !a.important) {
          return 1;
        }
        let seq = b.seq - a.seq;
        if (seq) {
          return seq;
        }
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    },
    timeAgoUntil: timeAgoUntil,
    toggleTag(tag) {
      let thisTag = this.allTags[tag.id];
      thisTag.selected = !thisTag.selected;
      this.$set(this.allTags, tag.id, thisTag);
      this.reflowContent();
    },
  },
  mounted() {
    this.setupReflowObserver();
    window.addEventListener("resize", this.resized);

    const onload = () => {
      window.removeEventListener("load", onload);
      this._reflow();
    };
    window.addEventListener("load", onload);
  },
  unmouted() {
    window.removeEventListener("resize", this.resized);
  },
};
</script>
