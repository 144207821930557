<template>
  <div id="cookies-dialog-wrapper" v-if="consentSet === null">
    <div id="cookies-dialog">
      <h1>About Cookies</h1>
      <p>
        This site uses cookies to enhance your experience on this site. We do
        not share information with third parties or collect personal
        information.
      </p>
      <p>
        Read more about how we handle personal information in our
        <router-link to="/article/privacy-policy">Privacy Policy</router-link>.
      </p>
      <div class="button-bar">
        <span class="allow-label">Allow:</span>
        <button id="all-cookies" v-on:click="changeConsent('all')">
          All cookies
        </button>
        <button id="minimum-cookies" v-on:click="changeConsent('required')">
          Only required cookies
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import cookies from "vue-cookies";

export default {
  name: "Cookies",
  data: function () {
    return {
      consentSet: null,
    };
  },
  methods: {
    changeConsent(type) {
      switch (type) {
        case "all":
          cookies.set("consent", "all", "2y");
          cookies.set("client", this.randomString(), "2y");
          break;
        case "required":
          cookies.set("consent", "required", "2y");
          cookies.remove("client");
          break;
      }
      this.consentSet = type;
    },
    isNil(a) {
      return a === null || a === undefined;
    },
    randomString() {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const len = 32;
      let result = "";
      for (let i = 0; i < len; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
  },
  mounted() {
    this.consentSet = cookies.get("consent");
    if (this.isNil(this.consentSet)) {
      if (this.consentSet === "all") {
        let clientCookie = cookies.get("client");
        if (this.isNil(clientCookie)) {
          clientCookie = this.randomString();
          cookies.set("client", clientCookie, "2y");
        }
      }
    }
  },
};
</script>
