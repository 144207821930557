<template>
  <footer>
    <div class="colorbars">
      <span id="c1"></span>
      <span id="c2"></span>
      <span id="c3"></span>
      <span id="c4"></span>
      <span id="c5"></span>
      <span id="c6"></span>
      <span id="c7"></span>
      <span id="c8"></span>
    </div>
    <div id="steam">
      STE<span class="a-for-art">A</span>M<span class="sep">:</span>
      <span class="steam-definition">
        Science Technology Engineering
        <a href="https://en.wikipedia.org/wiki/STEAM_fields">
          <span class="a-for-art">Art/Design</span>
        </a>
        Mathematics
      </span>
    </div>
    <div id="socials">
      <p v-if="!skipByline">
        Thomas Wilkinson has over 25 years of professional experience in
        platforms ranging from mobile devices to big iron.
      </p>
      <a href="/about">About Me</a>
      <span class="bar"> [ </span>
      <a rel="me" href="https://techhub.social/@tdelbert">Mastodon</a>
      <span class="bar"> | </span>
      <a rel="me" href="https://twitter.com/CapnAjax">Twitter</a>
      <span class="bar"> | </span>
      <a rel="me" href="https://www.linkedin.com/in/thomasdwilkinson/"
        >LinkedIn</a
      >
      <span class="bar"> ] </span>
      <span class="pronouns be-nice">he/him/his/himself</span>
      <span class="chinese-name be-nice" title="Wèi Bótè"> 魏柏特</span>
    </div>
    <div id="smallprint">
      <small>
        Copyright © MMXIV-MMXXIII Thomas Wilkinson. All rights reserved.
      </small>
      <small id="ibm-employee" v-if="!skipDisclosure">
        Disclosure: Thomas Wilkinson is an employee of
        <a href="https://www.ibm.com">IBM Corporation</a>. Some content on this
        site discusses IBM products and projects that IBM may have a strategic
        interest in. The opinions regarding these and all other products,
        projects, and issues on this site are Thomas Wilkinson&apos;s alone.
        Thomas Wilkinson does not speak on IBM&apos;s behalf and IBM does not
        direct, fund, or influence the content on this site.
      </small>
      <small id="privacy">
        <a href="/article/privacy-policy">Privacy policy</a>
      </small>
      <small class="disclaimer">
        <span class="heading">Disclaimer of warranty:</span> The content on this
        site is written by a volunteer as a public service in a complex and
        ever-changing technological landscape. It is provided as-is with no
        warranty for currency, accuracy, continuous maintenance, fitness for
        purpose or any other reason. Use of this site and its content implies
        acceptance of this condition.
      </small>
      <small class="trademarks">
        "Cap'n Ajax", "Captain Ajax", the rocket ship logo, and the RocketCode
        gradient are service marks of Thomas Wilkinson.
      </small>
      <small class="trademarks">
        &quot;IBM API Connect&quot; and &quot;DataPower&quot; are registered
        trademarks of IBM Corporation. &quot;Linux&quot; is a registered trade
        mark of the Linus Torvalds. &quot;Kubernetes&quot; is a registered trade
        mark of the Linux Foundation. All other trade marks are property of
        their respective owners.
      </small>
      <small>
        Social icons provided by
        <a href="https://icons8.com">Icons8, LLC</a>
        &copy; 2023.
      </small>
      <small>
        The following images are from Wikimedia Commons, licensed by
        <a href="http://creativecommons.org/licenses/by-sa/3.0/">
          CC BY-SA 3.0
        </a>
        , modified by Thomas D Wilkinson:
        <a href="http://commons.wikimedia.org/wiki/File:Human-brain.SVG">
          brain icon
        </a>
        ,
        <a
          href="http://commons.wikimedia.org/wiki/File:Topkapi_Palace_plan.svg"
        >
          blueprint icon
        </a>
        ,
        <a
          href="http://commons.wikimedia.org/wiki/File:Turbofan_operation_lbp.svg"
          >turbofan icon
        </a>
        ,
        <a href="http://commons.wikimedia.org/wiki/File:Rubik's_cube.svg">
          puzzle cube icon
        </a>
        .
      </small>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    skipByline() {
      return this.skips && this.skips.includes("byline");
    },
    skipDisclosure() {
      return this.skips && this.skips.includes("disclosure");
    },
  },
  props: {
    skips: Array,
  },
};
</script>
