<template>
  <div v-bind:class="`${article.form_factor} ${article.content_type}`">
    <div class="overline">
      <div class="no-overline" v-if="article.overlineParts.length === 0"></div>
      <div
        class="overline-part"
        v-for="op in article.overlineParts"
        v-bind:key="op.index"
        v-bind:style="op.style"
      ></div>
    </div>
    <ArticleTags
      v-bind:tags="article.tags"
      v-bind:selected="selectedTagIds || []"
    />
    <div class="title">
      {{ article.info.title }}
    </div>
    <div class="abstract">
      {{ article.info.abstract.text }}
    </div>
  </div>
</template>

<script>
import ArticleTags from "./ArticleTags.vue";

export default {
  name: "RocketArticle",
  components: {
    ArticleTags,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    selectedTagIds: {
      type: Array,
      required: false,
    },
  },
};
</script>
