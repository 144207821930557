import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About.vue";
import Article from "../views/Article.vue";
import Home from "../views/Home.vue";
import Links from "../views/Links.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
  },
  {
    path: "/article/:permalink",
    name: "Article",
    component: Article,
  },
  {
    path: "/links",
    name: "Links",
    component: Links,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
