<template>
  <div class="links">
    <h1>Links</h1>
    <div id="links-wrapper">
      <section id="api-connect">
        <h2>IBM API Connect</h2>
        <div class="link-group">
          <h3>API Connect Version 10</h3>
          <div class="links">
            <a
              href="https://www.ibm.com/support/knowledgecenter/en/SSMNED_v10/mapfiles/getting_started.html"
              >IBM Documentation</a
            >
            <a href="https://www.ibm.com/docs/en/datapower-gateways/10.0.1"
              >DataPower</a
            >
            <a href="https://apic-api.apiconnect.ibmcloud.com/v10/"
              >API Documentation</a
            >
            <a
              href="https://community.ibm.com/community/user/integration/viewdocument/ibm-api-connect-v10x-deployment-wh"
              >White Paper and Deployment Guide</a
            >
          </div>
          <h3>API Connect Version 2018</h3>
          <div class="links">
            <a
              href="https://www.ibm.com/support/knowledgecenter/en/SSMNED_2018/mapfiles/getting_started.html"
              >IBM Documentation</a
            >
            <a
              href="https://www.ibm.com/docs/en/datapower-gateways/2018.4?topic=SS9H2Y_7.7.0/com.ibm.dp.doc/welcome.html"
              >DataPower</a
            >
            <a href="https://apic-api.apiconnect.ibmcloud.com/v2018"
              >API Documentation</a
            >
            <a
              href="https://developer.ibm.com/apiconnect/2019/02/08/api-connect-v2018-deployment-whitepaper-now-available/"
              >White Paper and Deployment Guide</a
            >
          </div>
          <h3>API Connect Version 5</h3>
          <div class="links">
            <a href="https://www.ibm.com/docs/en/api-connect/5.0.x"
              >IBM Documentation</a
            >
          </div>
          <h3>IBM</h3>
          <div class="links">
            <a href="https://www.ibm.com/support/fixcentral/">Fix Central</a>
          </div>
          <h3>Blogs</h3>
          <div class="links">
            <a
              href="https://chrisphillips-cminion.github.io/categories.html#apiconnect"
              >Chris Phillips</a
            >
          </div>
        </div>
      </section>

      <section id="kubernetes">
        <h2>Kubernetes</h2>
        <div class="link-group">
          <h3>Blogs</h3>
          <div class="links">
            <a href="https://practicalkubernetes.blogspot.com"
              >Practical Kubernetes - Andre Tost</a
            >
          </div>
        </div>
      </section>

      <section id="mdn">
        <h2>MDN JavaScript</h2>
        <div class="link-group">
          <div class="links">
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects"
            >
              Standard Built-in Objects
            </a>
            <div class="sublinks">
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array"
                >Array</a
              >
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date"
                >Date</a
              >
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object"
                >Object</a
              >
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String"
                >String</a
              >
            </div>
          </div>
        </div>
      </section>

      <section id="nodejs">
        <h2>NodeJS</h2>
        <div class="link-group">
          <div class="links">
            <a href="https://nodejs.org/dist/latest-v16.x/docs/api/">
              Standard Built-in Objects
            </a>
            <div class="sublinks">
              <a
                href="https://nodejs.org/dist/latest-v16.x/docs/api/buffer.html"
                >Buffer</a
              >
              <a href="https://nodejs.org/dist/latest-v16.x/docs/api/fs.html"
                >File System</a
              >
              <a href="https://nodejs.org/dist/latest-v16.x/docs/api/http.html"
                >HTTP</a
              >
              <a href="https://nodejs.org/dist/latest-v16.x/docs/api/util.html"
                >Util</a
              >
            </div>
          </div>
        </div>
      </section>

      <section id="ExpressJS">
        <h2>ExpressJS</h2>
        <div class="link-group">
          <div class="links">
            <a href="http://expressjs.com/en/4x/api.html">API Reference</a>
          </div>
        </div>
      </section>
    </div>
    <Footer />
    <Cookies />
  </div>
</template>

<script>
import Cookies from "../components/Cookies.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Links",
  components: {
    Cookies,
    Footer,
  },
  data() {
    return {
      minColumnWidth: 270,
      gutter: 36,
      leftMargin: 0,
      rightMargin: 36,
    };
  },
  methods: {
    flowColumns() {
      let wrapper = document.getElementById("links-wrapper");
      let width = wrapper.clientWidth;
      if (!width) {
        return;
      }

      let numColumns = Math.floor(
        (width - this.leftMargin - this.rightMargin + this.gutter) /
          (this.minColumnWidth + this.gutter)
      );
      if (numColumns < 1) {
        numColumns = 1;
      }
      let availableForColumns =
        width -
        this.leftMargin -
        this.rightMargin -
        (numColumns - 1) * this.gutter;
      let columnWidth = Math.floor(availableForColumns / numColumns);
      let columns = [];
      for (let i = 0; i < numColumns; i++) {
        columns[i] = {
          left: this.leftMargin + (this.gutter + columnWidth) * i,
          height: 0,
        };
      }

      // flow the link-groups
      const heightExtremes = () => {
        let result = {
          max: {
            column: -1,
            height: 0,
          },
          min: {
            column: -1,
            height: Number.MAX_SAFE_INTEGER,
          },
        };
        for (let i in columns) {
          if (columns[i].height > result.max.height) {
            result.max = {
              column: i,
              height: columns[i].height,
            };
          }
          if (columns[i].height < result.min.height) {
            result.min = {
              column: i,
              height: columns[i].height,
            };
          }
        }
        return result;
      };
      let sections = document.querySelectorAll("#links-wrapper section");
      for (let section of sections) {
        let mh = heightExtremes().min;
        let sectionHeight = section.clientHeight;
        let left = this.leftMargin + (this.gutter + columnWidth) * mh.column;
        section.style = `position: absolute; left: ${left}px; top: ${mh.height}px; width: ${columnWidth}px`;
        columns[mh.column].height += sectionHeight + this.gutter;
      }
      document.querySelector(
        "#links-wrapper"
      ).style = `position: relative; height: ${heightExtremes().max.height}px`;
    },
  },
  mounted() {
    this.$nextTick(function () {
      let checkSizeInterval;
      let sizeTry = 0;
      let self = this;
      let checkSize = () => {
        sizeTry++;
        let linksWrapper = document.getElementById("links-wrapper");
        if (linksWrapper && linksWrapper.clientWidth) {
          clearInterval(checkSizeInterval);
          self.flowColumns();
        }
        if (sizeTry > 100) {
          clearInterval(checkSizeInterval);
        }
      };
      checkSizeInterval = setInterval(checkSize, 5);
    });
  },
};
</script>
