<template>
  <div class="article-tags">
    <div class="date" v-if="hasDate">
      <router-link v-if="hasDateLink" v-bind:to="dateLink">
        <span v-if="isUpdated" class="updated">
          <span class="title">updated</span>
          <span class="updated_dt">{{ date }}</span>
        </span>
        <span v-else class="active_dt">{{ date }}</span>
      </router-link>
      <span v-else-if="isUpdated" class="updated">
        <span class="title">updated</span>
        <span class="updated_dt">{{ date }}</span>
      </span>
      <span v-else class="active_dt">{{ date }}</span>
    </div>
    <div id="important-tags">
      <div
        class="tag"
        v-for="tag in tags"
        v-bind:key="tag.id"
        v-bind:style="tag.color ? `color: ${tag.color}` : ''"
        v-bind:data-selected="isSelected(tag.id)"
      >
        <div class="icon" v-bind:style="'background-color: ' + tag.color"></div>
        {{ tag.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { timeAgoUntil } from "nice-dates";
export default {
  computed: {
    date() {
      console.log(
        "this.updatedDate, this.createdDate:",
        this.updatedDate,
        this.createdDate
      );
      if (this.updatedDate && this.updatedDate !== this.createdDate) {
        return timeAgoUntil(new Date(this.updatedDate));
      } else if (this.createdDate) {
        return timeAgoUntil(new Date(this.createdDate));
      } else {
        return null;
      }
    },
    hasDate() {
      return !!(this.createdDate || this.updatedDate);
    },
    hasDateLink() {
      return !!this.dateLink;
    },
    isSelected() {
      // eslint-disable-next-line
      return function (id) {
        return this.selected?.includes(id);
      };
    },
    isUpdated() {
      if (this.updatedDate && this.updatedDate !== this.createdDate) {
        return true;
      } else if (this.createdDate) {
        return false;
      } else {
        return null;
      }
    },
  },
  name: "ArticleTags",
  props: {
    createdDate: {
      type: String,
      required: false,
    },
    dateLink: {
      type: String,
      required: false,
    },
    selected: {
      // array of ids
      type: Array,
      required: false,
    },
    tags: {
      // tags have id, title, and possibly a color
      type: Array,
      required: true,
    },
    updatedDate: {
      type: String,
      required: false,
    },
  },
};
</script>
