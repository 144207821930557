<template>
  <div class="about">
    <section id="services">
      <h2>Services</h2>
      <ul id="services-icons">
        <li class="service" id="services-difficult-components">
          <div class="ornament">
            <img src="/images/Difficult.svg" alt="" />
          </div>
          <h4>Difficult components</h4>
          <p>Expert problem-solving skills to make the impossible easy</p>
        </li>
        <li class="service" id="services-performance">
          <div class="ornament">
            <img src="/images/Performance.svg" alt="" />
          </div>
          <h4>Performance</h4>
          <p>Make your app run smoother, or make your server work harder</p>
        </li>
        <li class="service" id="services-app-architecture">
          <div class="ornament">
            <img src="/images/Architecture.svg" alt="" />
          </div>
          <h4>App architecture</h4>
          <p>Get your project started right or clean up years of legacy code</p>
        </li>
        <li class="service" id="services-mentoring">
          <div class="ornament">
            <img src="/images/Mentoring.svg" alt="" />
          </div>
          <h4>Mentoring</h4>
          <p>Make your own team stronger and your project successful</p>
        </li>
      </ul>
      <!--div id="services-logos">
        <a href="http://arduino.cc"
          ><img
            id="services-logo-arduino"
            alt="Arduino"
            src="/images/logos/ArduinoCommunity.svg" /></a
        ><a href="http://appcelerator.com"
          ><img
            id="services-logo-appc"
            alt="Appcelerator"
            src="/images/logos/appc-logo.svg" /></a
        ><a href="http://www.w3.org/html/logo/"
          ><img
            id="services-logo-html5"
            alt="HTML5"
            src="/images/logos/html5.svg" /></a
        ><a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
          ><img
            id="services-logo-js"
            alt="JavaScript"
            src="/images/logos/js.svg" /></a
        ><a href="http://nodejs.org"
          ><img
            id="services-logo-nodejs"
            alt="Node.js"
            src="/images/logos/nodejs-light.svg" /></a
        ><a href="http://dojotoolkit.org"
          ><img id="services-logo-dojo" alt="Dojo" src="/images/logos/dojo.svg"
        /></a>
      </div-->
    </section>
    <section id="whoami">
      <h2>
        Science&nbsp;Technology Engineering&nbsp;<span class="a-for-art"
          >Art</span
        >&nbsp;Mathematics
      </h2>
      <main id="whocontent">
        <img
          id="photospot"
          alt=""
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        />
        <p>
          Thomas Wilkinson has over 25 years of professional experience on
          platforms ranging from handheld devices to big iron and in industries
          from health care to heavy industry, manufacturing to major retail,
          food service to finance, infotech to infinity and beyond.
        </p>
        <p>
          He is a strong coder and a creative problem solver who can think
          outside the box when conventional solutions cannot work. He is also a
          strong mentor who provides trusted advice, writes clear documentation,
          and dedicated mentorship, with a single goal: to strengthen your team
          for long-term success.
        </p>
        <p>
          He takes the greatest joy in projects that allow him to express his
          <em>creative problem solving</em> skills, enabling
          <em>innovative companies</em> to create <em>slick</em> and
          <em>exciting apps</em>.
        </p>
        <p id="capnajax-why">
          The "Cap'n Ajax" moniker is a personal brand for a JavaScript expert,
          with skills forged in Titanium, Node.js, and Enterprise Middleware.
        </p>
        <p id="capnajax-c2a">
          Contact Thomas today
          <a
            title="Twitter"
            class="twitter"
            href="https://twitter.com/CapnAjax"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="fill: #000000"
              x="0px"
              y="0px"
              width="24"
              height="24"
              viewBox="0,0,256,256"
            >
              <g
                fill="#193380"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
              >
                <g transform="scale(10.66667,10.66667)">
                  <path
                    d="M22,3.999c-0.78,0.463 -2.345,1.094 -3.265,1.276c-0.027,0.007 -0.049,0.016 -0.075,0.023c-0.813,-0.802 -1.927,-1.299 -3.16,-1.299c-2.485,0 -4.5,2.015 -4.5,4.5c0,0.131 -0.011,0.372 0,0.5c-3.353,0 -5.905,-1.756 -7.735,-4c-0.199,0.5 -0.286,1.29 -0.286,2.032c0,1.401 1.095,2.777 2.8,3.63c-0.314,0.081 -0.66,0.139 -1.02,0.139c-0.581,0 -1.196,-0.153 -1.759,-0.617c0,0.017 0,0.033 0,0.051c0,1.958 2.078,3.291 3.926,3.662c-0.375,0.221 -1.131,0.243 -1.5,0.243c-0.26,0 -1.18,-0.119 -1.426,-0.165c0.514,1.605 2.368,2.507 4.135,2.539c-1.382,1.084 -2.341,1.486 -5.171,1.486h-0.964c1.788,1.146 4.065,2.001 6.347,2.001c7.43,0 11.653,-5.663 11.653,-11.001c0,-0.086 -0.002,-0.266 -0.005,-0.447c0,-0.018 0.005,-0.035 0.005,-0.053c0,-0.027 -0.008,-0.053 -0.008,-0.08c-0.003,-0.136 -0.006,-0.263 -0.009,-0.329c0.79,-0.57 1.475,-1.281 2.017,-2.091c-0.725,0.322 -1.503,0.538 -2.32,0.636c0.834,-0.5 2.019,-1.692 2.32,-2.636z"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
          <a
            title="Mastodon"
            class="mastodon"
            href="https://techhub.social/@tdelbert"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="fill: #000000"
              x="0px"
              y="0px"
              width="32"
              height="32"
              viewBox="0,0,256,256"
            >
              <g
                fill="#193380"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
              >
                <g transform="scale(8,8)">
                  <path
                    d="M15.9375,4.03125c-3.0205,0.01225 -6.01958,0.39573 -7.58008,1.11523c0,0 -3.35742,1.52837 -3.35742,6.73437c0,6.197 -0.0045,13.97938 5.5625,15.48438c2.132,0.573 3.96545,0.69633 5.43945,0.61133c2.675,-0.151 3.99805,-0.9707 3.99805,-0.9707l-0.08984,-1.97656c0,0 -1.73386,0.61102 -3.88086,0.54102c-2.127,-0.075 -4.3678,-0.23462 -4.7168,-2.89062c-0.032,-0.247 -0.04787,-0.49709 -0.04687,-0.74609c4.507,1.119 8.3502,0.48738 9.4082,0.35938c2.954,-0.359 5.52552,-2.2113 5.85352,-3.9043c0.514,-2.668 0.4707,-6.50781 0.4707,-6.50781c0,-5.206 -3.35156,-6.73437 -3.35156,-6.73437c-1.6455,-0.7685 -4.68848,-1.12748 -7.70898,-1.11523zM12.70508,8.00195c1.03487,0.02775 2.0575,0.49075 2.6875,1.46875l0.60938,1.03516l0.60742,-1.03516c1.265,-1.967 4.10022,-1.84427 5.44922,-0.32227c1.244,1.448 0.9668,2.38256 0.9668,8.85156v0.00195h-2.44727v-5.62891c0,-2.635 -3.35937,-2.73677 -3.35937,0.36523v3.26172h-2.43164v-3.26172c0,-3.102 -3.35742,-3.00219 -3.35742,-0.36719v5.62891h-2.45313c0,-6.474 -0.2722,-7.41456 0.9668,-8.85156c0.6795,-0.766 1.72684,-1.17423 2.76172,-1.14648z"
                  />
                </g>
              </g>
            </svg>
          </a>
          <a
            title="LinkedIn"
            class="linkedin"
            href="https://www.linkedin.com/in/thomasdwilkinson/"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="fill: #000000"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0,0,256,256"
            >
              <g
                fill="#193380"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
              >
                <g transform="scale(8.53333,8.53333)">
                  <path
                    d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM10.496,8.403c0.842,0 1.403,0.561 1.403,1.309c0,0.748 -0.561,1.309 -1.496,1.309c-0.842,0.001 -1.403,-0.561 -1.403,-1.309c0,-0.748 0.561,-1.309 1.496,-1.309zM12,20h-3v-8h3zM22,20h-2.824v-4.372c0,-1.209 -0.753,-1.488 -1.035,-1.488c-0.282,0 -1.224,0.186 -1.224,1.488c0,0.186 0,4.372 0,4.372h-2.917v-8h2.918v1.116c0.376,-0.651 1.129,-1.116 2.541,-1.116c1.412,0 2.541,1.116 2.541,3.628z"
                  />
                </g>
              </g>
            </svg>
          </a>
        </p>
        <p id="tomname">
          Thomas doesn't like being called "Tom". It hurts his feelings.
        </p>
      </main>
    </section>
    <Footer></Footer>
    <Cookies />
  </div>
</template>

<script>
import Cookies from "../components/Cookies.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "About",
  components: {
    Cookies,
    Footer,
  },
};
</script>
