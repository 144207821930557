<template>
  <span v-bind:class="outerClass">
    <span
      v-for="ss in styledStripes"
      v-bind:key="ss.id"
      v-bind:style="ss.style"
    ></span>
    <span class="defacement" v-if="deface">
      <span class="cheat" title="Cheat Notes" v-if="deface === 'cheat'">♫</span>
      <svg
        v-else
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        viewBox="0,0,256,256"
      >
        <g
          v-if="deface === 'rocket'"
          fill="#ffffff"
          fill-rule="nonzero"
          stroke="none"
        >
          <g transform="scale(8.53333,8.53333)">
            <path
              d="M26.97852,3.02148c0,0 -8.97852,-0.02148 -15.97852,6.97852c-0.32319,0.32319 -0.60459,0.67502 -0.85937,1.03906c-1.24108,-0.09923 -3.14086,-0.06681 -4.11328,0.90625c-2.27,2.27 -3.02734,6.05469 -3.02734,6.05469l5,-0.71484v1.71484l3,3h1.71484l-0.71484,5c0,0 3.78469,-0.75734 6.05469,-3.02734c0.97306,-0.97242 1.00548,-2.8722 0.90625,-4.11328c0.36404,-0.25478 0.71587,-0.53619 1.03906,-0.85937c7,-7 6.97852,-15.97852 6.97852,-15.97852zM19,9c1.105,0 2,0.895 2,2c0,1.105 -0.895,2 -2,2c-1.105,0 -2,-0.895 -2,-2c0,-1.105 0.895,-2 2,-2zM7.19922,19.99609c-0.38,0.1 -0.74011,0.29089 -1.03711,0.58789c-1.366,1.366 -1.14844,4.40039 -1.14844,4.40039c0,0 3.01444,0.23556 4.39844,-1.14844c0.297,-0.297 0.48789,-0.65906 0.58789,-1.03906l-0.45703,-0.45703c-0.045,0.064 -0.07286,0.13831 -0.13086,0.19531c-0.975,0.975 -2.4375,0.48828 -2.4375,0.48828c0,0 -0.48772,-1.4625 0.48828,-2.4375c0.058,-0.058 0.12936,-0.08486 0.19336,-0.13086z"
            ></path>
          </g>
        </g>
        <g v-else-if="deface === 'featured'" fill="#ffffff" fill-rule="nonzero">
          <g
            transform="translate(-20.622,24.28441) rotate(-10) scale(10.66667,10.66667)"
          >
            <path
              d="M12,18.091l4.969,2.999c0.784,0.473 1.751,-0.23 1.543,-1.121l-1.319,-5.653l4.391,-3.804c0.692,-0.599 0.322,-1.736 -0.59,-1.813l-5.78,-0.49l-2.261,-5.335c-0.357,-0.841 -1.549,-0.841 -1.906,0l-2.261,5.335l-5.78,0.49c-0.912,0.077 -1.282,1.214 -0.59,1.813l4.391,3.804l-1.319,5.653c-0.208,0.891 0.759,1.594 1.543,1.121z"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  </span>
</template>

<script>
export default {
  computed: {
    importantTags() {
      console.log("this.deface:", this.deface);
      console.log(
        "this.tags:",
        this.tags?.map((t) => t?.title),
        this.tags
      );
      let tags = this.tags;
      let result = tags
        ? tags
            .filter((t) => t && t.important)
            .filter((t, i, a) => {
              for (let j = 0; j < i; j++) {
                if (a[j].id === t.id) {
                  return false;
                }
              }
              return true;
            })
            .sort((a, b) => {
              const normalize = (c) => {
                if (c === undefined) {
                  return null;
                }
                if (typeof c === "string") {
                  let result = Number.parseInt(c);
                  if (isNaN(result)) {
                    return null;
                  } else {
                    return result;
                  }
                }
                if (typeof c === "number") {
                  return c;
                }
                return null;
              };
              let sortA = normalize(a);
              let sortB = normalize(b);
              if (sortA === sortB) {
                return 0;
              }
              if (sortA === null) {
                return -1;
              }
              if (sortB === null) {
                return 1;
              }
              return sortA - sortB;
            })
        : [];
      return result;
    },
    outerClass() {
      if (this.importantTags.length == 0) {
        return "article-icon no-icon";
      } else {
        return "article-icon";
      }
    },
    styledStripes() {
      let it = this.importantTags;
      let result = [];
      let width = 100.0 / it.length;
      for (let ti in it) {
        let t = it[ti];
        result.push({
          style: `
            background-color: ${t.color};
            position: absolute;
            top: 0px;
            bottom: 0px;
            display: block;
            left: ${ti * width}%;
            width: ${width}%;
          `,
          name: t.title,
          id: ti,
        });
      }
      return result;
    },
  },
  name: "ArticleIcon",
  props: {
    deface: String,
    tags: {
      // tags have id, title, and possibly a color
      type: Array,
      required: true,
    },
  },
};
</script>
